import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/default_layout.js";
import { genDateTimes } from "../../components/date_time";
import { NextDeadline } from "./_deadline";
import { InlineIcon } from "@iconify/react";
export const prelim_prob_deadlines = genDateTimes("2021-10-21 13:00", 6, 1).map((dateTime, i) => ({
  dateTime: dateTime,
  task: `Sheet ${i + 1}`
}));
export const _frontmatter = {};
const layoutProps = {
  prelim_prob_deadlines,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1>{`Prelim Statistics 2024`}</h1>
    <p>{`Details to be confirmed`}</p>
    <h1>{`Graph Theory 2024`}</h1>
    <p>{`Details to be confirmed`}</p>
    <h1>{`General teaching material`}</h1>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://drive.google.com/file/d/14N05H3Y2tDqArJmXpim350ghxqtPf0ZU/view?usp=sharing"
        }}>{`Probability Distributions Anki Deck`}</a></li>
    </ul>
    <h1>{`Tips on example sheets`}</h1>
    <ul>
      <li parentName="ul">{`Make sure the following is on your work (in the filename for digital submissions, on the front page for physical submissions):`}
        <ul parentName="li">
          <li parentName="ul">{`Your name`}</li>
          <li parentName="ul">{`The course name`}</li>
          <li parentName="ul">{`The sheet number`}</li>
        </ul>
      </li>
      <li parentName="ul">{`For physical submissions, my name should also be on the front page`}</li>
      <li parentName="ul">{`For scanned work, use a scanner or a phone app like Noteblock to properly crop your work`}</li>
      <li parentName="ul">{`Make your answers clear, e.g. `}<span style={{
          border: "1px solid"
        }}>{`add a box (made you look)`}</span></li>
      <li parentName="ul">{`For longer proofs, consider breaking it up into lemmas`}</li>
      <li parentName="ul">{`An incomplete answer (clearly marked as such) is preferable to no answer`}</li>
      <li parentName="ul">{`Do not leave your submission to last minute`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      